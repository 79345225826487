@import "../../../../styles/variables";

.drawer {
  border-radius: 20px 20px 0 0;

  .deleteTitle {
    color: #fc4646;
  }
  //   .icon {
  //     background-color: #4265f6 !important;
  //     width: 40px;
  //   }
}
.container {
  min-height: 100vh;
  transition: all 0.3s ease;
  &.light {
    // background-color: #000;
    color: #000;
  }
  &.dark {
    color: #fff;
    background-color: $bg-dark;
  }
}
