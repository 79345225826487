.profileBox {
  display: flex;
  align-items: center;
  img {
    margin-right: 12px;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-right: 150px;
  }
}
