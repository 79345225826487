.navbar {
  background-color: #f9f9f9;
  // position: fixed;
  position: sticky;
  z-index: 2;
  // top: -50px;
  margin-bottom: 20px;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  transition: top 0.3s;
  padding: 15px 0 !important;
  // box-shadow: 0px 4px 62px rgba(153, 171, 198, 0.18);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  .container {
    display: flex;
    align-items: start;
    .button {
      background-color: #4265f6;
      width: 40px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5px;
      margin-left: 10px;
    }
    .buttonClear {
      // background-color: #4265f6;
      width: 40px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5px;
      margin-left: 10px;
    }
  }
}
