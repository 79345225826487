@import "../../styles/variables";

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .logo {
    span {
      transition: all 0.3s ease;
      width: 100px !important;
      height: 150px !important;
      //   margin-bottom: 25px;
    }
    @media only screen and (min-width: 600px) {
      display: none;
    }

    // @media only screen and (max-width: 547px) {
    //   display: inline-block;
    // }
  }
  .logoLight {
    background-color: $blue-bg !important;
  }
  .logoDark {
    background-color: $bg-light !important;
  }

  .courseApp {
    width: 300px;
    margin-bottom: 29px;
  }

  .content {
    .title {
      font-size: 42px;
      position: relative;

      span {
        color: $blue-bg;

        &::before {
          content: "";
          position: absolute;
          bottom: -20px;
          width: 300px;
          height: 20px;
          background-image: url(../../assets/icons/source/line.svg);
          background-repeat: no-repeat;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      text-align: center !important;

      .title {
        text-align: center !important;
      }
    }
  }
}
