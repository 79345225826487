.statisticCard {
  height: 147px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;

  &.light {
    color: #4265f6;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .icon {
      background-color: #4265f6;
    }
  }
  &.dark {
    // background: #343436;
    color: #777;
    border: 1px solid #ffffff15;
    -webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

    .icon {
      background-color: #777;
    }
  }

  &:nth-of-type(2) {
    margin-left: 19px;
  }

  .icon {
    // background-color: #4265f6;
    width: 40px;
    height: 40px;
    margin-bottom: 12px;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    // color: #4265f6;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    // color: #4265f6;
  }
}
