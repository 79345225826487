@import "../../styles/variables";

.modal {
  &.light {
    background-color: $bg-light;
  }
  &.dark {
    background-color: $bg-dark;
  }

  .wrapper {
    width: 100%;
    text-align: center;
    .line {
      width: 40px;
      background-color: #5b5858 !important;
      margin-bottom: 18px;
    }
  }

  .button {
    color: #4265f6;
    font-weight: bold;
    background: #d9e0fc;
    box-shadow: 0px 4px 62px rgba(153, 171, 198, 0.18);
  }
}
