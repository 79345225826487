.imgBox {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  img {
    width: 100%;
  }
}

.card {
  margin-bottom: 16px;

  background: #ffffff;
  box-shadow: 0px 4px 62px rgba(153, 171, 198, 0.18);
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;

  .firstRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: black;
      // font-weight: bold;
      margin: 0;
    }

    .companyImg {
      display: flex;
      margin-right: 10px;
      justify-content: center;
      align-items: center;
      // background: #f5f5f5;

      img {
        border-radius: 50%;
        overflow: hidden;
        // border: 1px solid red;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        width: 40px;
        height: 40px;
      }
    }
  }

  .secondRow {
    margin-top: 10px;

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #141415;
      margin-bottom: 6px;
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #808080;
    }
  }

  .tags {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    span {
      padding: 6px 22px;
      background: #d7d7d756;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #808080;
    }
  }

  .thirdRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    .timePost {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: #808080;
    }

    p {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #808080;

      span {
        color: #141415;
      }
    }
  }
}
