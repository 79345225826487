@import "../../../styles/variables";

.arrow {
  // cursor: pointer;
  // padding: 0 20px 0 0;
  // // height: 50px;
  // padding: 25px 0;
  // display: flex;
  // align-items: center;
  position: sticky;
  z-index: 2;
  padding: 20px 12px;
  top: 0;
  width: 100%;
  // background-color: #fff;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  margin-bottom: 20px;
  transition: all 0.3s ease;

  .noneShadow {
    box-shadow: none;
  }

  &.light {
    background-color: $bg-white;
  }
  &.dark {
    background-color: $bg-dark;
    -webkit-box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }

  .icon {
    transition: all 0.3s ease;
    cursor: pointer;
    // background-color: #3b4657;
    // margin: 32px 0 40px 0;

    &.light {
      background-color: $bg-dark;
    }
    &.dark {
      background-color: $bg-light;
    }
  }

  .addIcon {
    font-size: 20px;

    &.light {
      color: $text-light;
      // background-color: $bg-dark;
    }
    &.dark {
      color: $text-dark;
      // background-color: $bg-light;
    }
  }

  .moreIcon {
    font-size: 23px;

    &.light {
      color: $text-light;
      // background-color: $bg-dark;
    }
    &.dark {
      color: $text-dark;
      // background-color: $bg-light;
    }
  }

  .shareIcon {
    font-size: 20px;
    margin-right: 7px;

    &.light {
      color: $text-light;
      // background-color: $bg-dark;
    }
    &.dark {
      color: $text-dark;
      // background-color: $bg-light;
    }
  }
}
