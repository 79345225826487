@import "../../styles/variables";

.navbar {
  width: 100%;
  height: 72px;
  padding-bottom: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  z-index: 3;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  &.light {
    background-color: #fff;
    box-shadow: 0px 4px 159px rgba(172, 200, 211, 0.582);
  }
  &.dark {
    background-color: $bg-dark;
    // box-shadow: -10px -10px 50px red;
    -webkit-box-shadow: 0px -4px 8px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px -4px 8px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }

  ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      &:last-child {
        margin-right: 0;
      }
      a {
        color: #000;
        text-decoration: none;

        span {
          background-color: #808080;
        }
      }
      .add {
        display: inline-block;
        background-color: #4265f6;
        width: 43px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        span {
          width: 18px;
          background-color: #fff;
        }
      }
    }
  }
}
