@import "../../styles/variables";

.bgBlue {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11111;
  width: 100%;
  height: 100vh;
  background-color: $blue-bg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 70px;
    height: 75px;
  }
}
