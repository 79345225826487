.container {
  .col {
    label {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #141415;
      margin-bottom: 12px;
    }

    textarea {
      min-height: 232px;
    }
  }
}
