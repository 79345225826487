.imgBox {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  img {
    width: 100%;
  }
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #141415;
  margin-top: 14px;
}

.buttons {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 4px 83px rgba(172, 200, 211, 0.2);
  padding: 16px 8px;

  .save {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    width: 48px;
    padding: 0 25px;
    height: 48px;
    box-shadow: 0px 20px 72px rgba(66, 101, 246, 0.4);
    border-radius: 15px;
  }

  .submit {
    // margin-left: 16px;
    width: 100%;
    height: 48px;
    border: none;
    background: #4265f6;
    box-shadow: 0px 4px 62px rgba(153, 171, 198, 0.18);
    border-radius: 6px;
    color: #fff;
    transition: all 0.3s ease;

    &.applied {
      // background-color: #52c41a;
      background-color: #4265f6cf;
    }
  }
}
