@import "../styles/converterRem";
@import "../styles/variables";

body {
  // background-color: #f9f9f9 !important;

  .icon {
    width: toRem(24);
    height: toRem(24);
    display: inline-block;
    mask-repeat: no-repeat;
    mask-size: 100%;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
    -webkit-mask-position: center;
    vertical-align: middle;
    background-color: $colorWhite;
  }

  .goBackIcon {
    background-color: $bg-dark;
  }
  .iconLight {
    span {
      background-color: $bg-light !important;
    }
  }

  .iconDark {
    span {
      background-color: black;
    }
  }

  .textBlue {
    color: $text-blue;
  }
  .textLight {
    color: $text-light;
  }
  .textDark {
    color: $text-dark;
  }

  .logo {
    transition: all 0.3s ease;
    width: 100px !important;
    height: 150px !important;
  }
  .logoLight {
    background-color: $blue-bg !important;
  }
  .logoDark {
    background-color: $bg-light !important;
  }
  a.active {
    span {
      background-color: #4265f6 !important;
    }
  }
  .img-40 {
    width: 40px;
    height: 40px;
  }

  .ant-drawer-body {
    padding-top: 0 !important;
  }

  .drawerBlueLine {
    background-color: #4265f6 !important;
    width: 40px;
    margin-bottom: 10px;
  }

  .hideEffect {
    transition: all 1s ease;
  }

  .hidePage {
    opacity: 0;
  }
  .showPage {
    opacity: 1;
  }
  .logoAuth {
    transition: all 0.3s ease;
    width: 100px !important;
    height: 150px !important;
  }

  .ant-upload-select,
  .ant-upload-list-item-container {
    width: 100% !important;
  }

  .drawerModal {
    border-radius: 20px 20px 0 0;
  }

  .danger {
    color: #fc4646;
  }

  .transitions {
    transition: all 0.3s ease;
    overflow: hidden;
  }
  .h0 {
    opacity: 0;
    height: 0;
  }

  .h1 {
    opacity: 1;
    height: 86px;
  }

  .antModalLight {
    .ant-modal-content {
      background-color: $bg-light;
    }
  }
  .antModalDark {
    .ant-modal-content {
      background-color: $bg-dark;
    }
  }

  .slick-dots,
  .slick-dots-bottom {
    bottom: 0 !important;
  }

  .truncate {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .truncateW100 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .placeholder {
    padding-top: 30vh;
    text-align: center;
    color: var(--adm-color-weak);
    .loadingWrapper {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }

  .ant-collapse-header {
    padding: 12px 0 !important;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .ant-collapse-header-text {
    font-weight: bold;
  }

  .inputDisableArrow input::-webkit-outer-spin-button,
  .inputDisableArrow input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
