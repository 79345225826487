.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 111;
  background-color: rgba(0, 0, 0, 0.473);
  //   opacity: 0.5;

  .box {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 222;
  }

  .logo {
    // background-color: #1677ff;
    position: relative;
    z-index: 222;
    width: 70px;
    height: 75px;
  }
}
