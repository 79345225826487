@import "../../styles/variables";

.wrapper {
  transition: all 0.3s ease;
  &.light {
    background-color: $bg-light;
  }
  &.dark {
    background-color: $bg-dark;
  }

  .container {
    min-height: 100vh;

    // background-color: red;
  }
}
