.header {
  padding: 12px 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 999999;
  top: 0;
  left: 0;
  .left {
    flex: auto;
  }
  .right {
    flex: none;
    padding-left: 12px;
  }
}

.placeholder {
  padding-top: 30vh;
  text-align: center;
  color: var(--adm-color-weak);
  .loadingWrapper {
    font-size: 24px;
    margin-bottom: 24px;
  }
}
