.button {
  width: 100%;
  height: 48px;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
}
