.row {
  padding-bottom: 75px;
  .list {
    height: 70px;
    background: #ffffff;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 20px;
    margin-bottom: 12px;
    border: 1px solid transparent;
    transition: all 0.3s ease;

    .title {
      display: flex;
      align-items: center;

      span {
        background-color: #4265f6;
        margin-right: 12px;
      }

      p {
        transition: all 0.3s ease;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #141415;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        transition: all 0.3s ease;
        width: 7px;
      }

      &.blue {
        background: #d9e0fd;
        span {
          background-color: #4265f6;
        }
      }

      &.black {
        span {
          background-color: #141415;
        }
      }
    }

    &.dark {
      background-color: #272b33;
      border-color: #7a828e;

      .title {
        p {
          color: #fff;
        }
      }

      .icon {
        &.black {
          span {
            background-color: #fff;
          }
        }
      }
    }
  }
}
