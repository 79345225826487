$blue-bg: #4265f6;
$bg-white: #fff;
$bg-light: #f9f9f9;
$bg-dark: #272b33;

$colorWhite: #fff;

$typography-light: #272b33;
$typography-dark: #ababab;

$text-blue: #4265f6;
$text-light: #272b33;
$text-dark: #ababab;

$tablet-width: 1199px;
$tablet2-width: 991px;
$mobile-width: 767px;
