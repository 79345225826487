.userCard {
  position: sticky;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-image: url(../../../../assets/images/svg/Background.svg);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: top;
  border-radius: 0 0 30px 30px;
  padding-bottom: 21px;
  transition: all 0.3s ease;
  height: 220px;

  @media only screen and (max-height: 470px) {
    &.hide {
      overflow: hidden;
      height: 62px;
      transition: all 0.3s ease;

      .profileBox {
        position: absolute;
        top: 10px;
        flex-direction: row;

        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }

      .edit {
        display: none;
        position: absolute;
        top: 50%;
        left: 65%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .profileBox {
    display: flex;
    flex-direction: column;

    img {
      width: 62px;
      height: 62px;
      margin-bottom: 7px;
    }

    .name {
      display: flex;
      flex-direction: column;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        // line-height: 28px;
        color: #ffffff;
      }
    }
  }

  .edit {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    background: #ffffff1e;
    border-radius: 6px;
    width: 150px;
    height: 30px;
    cursor: pointer;

    p {
      margin: 0;
      color: #fff;
      span {
        margin-left: 7px;
      }
    }
  }
}
