.container {
  //   height: 100vh;

  .upload {
    width: 100%;
    background: #f5f5f5;
    padding: 30px 0;
    border: 0.5px dashed #808080;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: medium;
    font-size: 14px;
    line-height: 15px;
    color: #141415;
    span {
      background-color: #4265f6;
      margin-right: 15px;
    }
  }
}
