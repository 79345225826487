@import "../../styles/_variables.scss";

.header {
  min-height: 100vh;
  overflow: auto;
  transition: all 0.3s ease;
}

.headerLight {
  background-color: $bg-light;
}
.headerDark {
  color: #fff;
  background-color: $bg-dark;
}
