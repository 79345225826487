@import "../../../styles/variables";

.btn {
  width: 60px;
  height: 60px;
  background-color: $blue-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: fixed;
  right: 26px;
  bottom: 20px;
}
